<template>
  <default-layout
    v-loading.lock="loadingSubmit"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
  >
    <div class="box-card">
      <div class="breadcrumb">
        <el-breadcrumb
          separator-class="el-icon-arrow-right"
          class="set-breadcrumb"
        >
          <el-breadcrumb-item :to="{ path: '/lead-scoring' }"
            >Lead Scoring</el-breadcrumb-item
          >
          <el-breadcrumb-item> รายการ Import Data</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <!-- title -->
      <h1>รายการ Import Data</h1>
      <LeadScoringQueueTable
        :queues="queues"
        :total="total"
        :loading="loadingSubmit"
        @fetchQueueList="fetchQueueEmit"
      />

      <!-- pagination -->
      <div class="text-center mg-t-5">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="changePage"
          :current-page.sync="filter.page"
          :page-sizes="[10, 30, 50, 100, 500]"
          :page-size="filter.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <!-- end pagination -->
    </div>
  </default-layout>
</template>

<script>
import { mapState } from "vuex";
import LeadScoringQueueTable from "@/components/leadScoring/QueueList";

export default {
  name: "LeadScoringQueue",
  components: {
    LeadScoringQueueTable,
  },
  mounted() {
    this.fetchQueueList();
  },
  data() {
    return {
      loadingSubmit: false,
      filter: {
        page: 1,
        limit: 10,
        skip: 0,
      },
      queues: [],
      total: 0,
    };
  },
  methods: {
    handleSizeChange(limit) {
      this.filter.page = 1;
      this.filter.limit = limit;
      this.filter.skip = this.filter.page * limit - this.filter.limit;
      this.fetchQueueList();
    },
    async changePage(page) {
      this.filter.page = page;
      this.filter.skip = page * this.filter.limit - this.filter.limit;
      await this.fetchQueueList();
    },
    async fetchQueueEmit(val) {
      await this.fetchQueueList();
    },
    async fetchQueueList() {
      try {
        this.loadingSubmit = true;
        // console.log("\n fetch queue");
        let res = await this.$store.dispatch(
          "leadscoring/fetchQueueList",
          this.filter
        );

        if (res.success) {
          this.loadingSubmit = false;
          this.queues = res.obj;
          this.total = res.total;
          return;
        } else {
          this.loadingSubmit = false;
          return;
        }
      } catch (e) {
        console.log("err fetch queue: ", e);
        this.loadingSubmit = false;
        return;
      }
    },
  },
  computed: {},
};
</script>
