<template>
  <div>
    <!-- fetch data -->
    <el-table
      class="table-normal animate__animated animate__fadeIn animate__slow w-100"
      :data="queues"
      border
      :v-loading="loading"
      element-loading-text="กำลังโหลดข้อมูล..."
      element-loading-spinner="el-icon-loading"
    >
      <template slot="empty">
        <div>
          <p class="font-30 font-weight-500">ไม่พบข้อมูลที่คุณค้นหา</p>
        </div>
      </template>
      <!-- <el-table-column label="เวลาที่นำเข้าข้อมูล"> -->
      <el-table-column label="วันเวลาที่นำเข้าข้อมูล">
        <template slot-scope="scope">
          {{ formatDate(scope.row.importDate) }}
        </template>
      </el-table-column>
      <el-table-column label="เจ้าหน้าที่ทำรายการ">
        <template slot-scope="scope">
          <span v-if="!!scope.row.officer">
            {{ scope.row.officer.empFname + " " + scope.row.officer.empLname }}
          </span>
          <span v-else> - </span>
        </template>
      </el-table-column>
      <el-table-column label="ชื่อไฟล์">
        <template slot-scope="scope">
          <span>{{ scope.row.file }}</span>
        </template>
      </el-table-column>
      <el-table-column label="สถานะการดำเนินการ">
        <template slot-scope="scope">
          <div v-html="executeStatusText(scope.row.status)"></div>
        </template>
      </el-table-column>
      <el-table-column prop="amountImport" label="ข้อมูลที่นำเข้าทั้งหมด">
      </el-table-column>
      <!-- <el-table-column label="เวลาที่นำเข้าข้อมูลสำเร็จ"> -->
      <el-table-column label="วันเวลาที่ทำรายการสำเร็จ">
        <template slot-scope="scope">
          {{ formatDate(scope.row.importSuccessDate) }}
        </template>
      </el-table-column>
      <el-table-column label="">
        <template slot-scope="scope">
          <el-button
            :disabled="scope.row.status != 1 ? true : false"
            @click="downloadFile(scope.row._id)"
            class="btn-queue-action text-primary"
            ><i class="fas fa-file-download font-20"></i
          ></el-button>
          <el-button
            @click="confirmDeleteQueue(scope.row._id, scope.row.file)"
            class="btn-queue-action queue-delete text-soft-danger"
            ><i class="fas fa-trash-alt font-20"></i
          ></el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- confirm delete queue -->
    <el-dialog
      class="delete-queue-dialog"
      title="ยืนยันการลบไฟล์ Import"
      :visible.sync="dialogVisible"
      width="40%"
    >
      <div class="dialog-custom-content text-center">
        <span>คุณต้องการลบไฟล์ Import ของคุณหรือไม่</span>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button
          @click="dialogVisible = false"
          class="font-16 btn-cancle min-100"
          >ยกเลิก</el-button
        >
        <el-button
          type="primary"
          class="font-16 submit min-100"
          @click="destroyQueue"
          >ยืนยัน</el-button
        >
      </span>
    </el-dialog>
    <!-- end confirm delete queue -->
  </div>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "LeadScoringQueueList",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    queues: {
      type: Array,
      default: [],
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      base_url: process.env.VUE_APP_BASE_URL,
      dialogVisible: false,
      queueId: null,
      filename: "",
    };
  },
  mounted() {},
  methods: {
    async downloadFile(id) {
      try {
        let res = await this.$store.dispatch("leadscoring/downloadFile", id);
        // console.log("res:", res);
        if (res.success) {
          let redirect_url = this.base_url + "excels/" + res.obj.filename;
          window.open(redirect_url, "_blank");
          return;
        }

        var message = res.data;
        if (res.status == 404) {
          message = "ไม่พบไฟล์!";
        }

        this.$message({
          message: message,
          type: "error",
          duration: 4000,
        });

        return;
      } catch (e) {
        console.log("error download file: ", e.message);
        this.$message({
          message: e.message,
          type: "error",
          duration: 4000,
        });
        return;
      }
    },
    confirmDeleteQueue(id, filename) {
      this.dialogVisible = true;
      this.queueId = id;
      this.filename = filename;
    },
    async fetchQueues() {},
    async destroyQueue() {
      try {
        const res = await this.$store.dispatch(
          "leadscoring/destroyQueue",
          this.queueId
        );
        if (res.success) {
          // fetch queue list

          this.$emit("fetchQueueList", null);

          this.dialogVisible = false;
          this.$message({
            message: "ลบไฟล์ import สำเร็จ!",
            type: "success",
            duration: 4000,
          });
          return;
        }
      } catch (e) {
        console.log("error delete queue: ", e.message);
        this.dialogVisible = false;
        this.$message({
          message: e.message,
          type: "error",
          duration: 4000,
        });
        return;
      }
    },
    formatDate(date) {
      if (!date) return "";
      moment.locale("th");
      const result = moment(date).format("D MMM YYYY เวลา HH.mm น.");
      return result;
    },
    executeStatusText(status) {
      switch (status) {
        case 1:
          // return `<span class="text-success">ไฟล์พร้อมดาวน์โหลด</span>`;
          return `<span class="text-success">ไฟล์ที่คำนวณ Lead แล้ว</span>`;
        case 2:
          // return `<span class="text-warning">กำลังคำนวน Lead Scroing...</span>`;
          return `<span class="text-warning">กำลังคำนวณ Lead Scoring...</span>`;
        case 3:
          // return `<span class="text-warning">กำลังรอคำนวน Lead Scroing...</span>`;
          return `<span class="text-warning">กำลังรอคำนวณ Lead Scoring...</span>`;
        case 4:
          return `<span class="text-secondary">Import ไฟล์ไม่สำเร็จ</span>`;
        default:
          return "";
      }
    },
    handleClose() {
      return (this.dialogVisible = false);
    },
  },
  computed: {
    // ...mapState({
    //   queues: (state) => state.leadscoring.queues,
    //   total: (state) => state.leadscoring.total,
    // }),
    // 0: null, 1: success, 2: in progress, 3: pending 4: failed
  },
};
</script>

<style lang="scss" scoped>
.btn-queue-action.el-button {
  border: none !important;
  background: transparent !important;
  padding: 0 5px !important;
  i {
    font-size: 24px !important;
  }
}

.btn-queue-action.queue-delete:hover {
  color: #ed1c24;
}
</style>
